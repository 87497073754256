export default class Player {
    static PARAMS = {
        SIZE: 12,
        MASS: 0.025,
        BOUNCE: 0.8,
        FRICTION: 0.025,
        FRICTION_AIR: 0.02,
        FRICTION_STATIC: 0.0001,
        INERTIA: 1000,
        TIMESCALE: 0.88,
        ANGLE: {
            min: -Math.PI,
            max: 0,
            initial: -Math.PI/4,
            initialReverse: -Math.PI*3/4,
            change_speed: 0.001
        },
        FORCE: {
            min: 0,
            max: 0.0017,
            pow: 0.9,
            initial: 0,
            change_speed_factor: 0.001
        },
        STANDING_CHECK: {
            time: 100,
            thresholdFull: 0.2,
            thresholdHalf: 0.14
        },
        MAX_PHYSICS_PLAYERS: 8,
        FONT_SIZE: 26
    };
    static statusEmojiMap = {
        "AFK": "😴",
        "DISCONNECTED": "☠",
        "FINISHED": "🏁"
    };

    isLocalPlayer = false;
    id = "";
    name = "";
    color = "#ffff00";
    points = 0;
    pointsDelta = 0;
    lastShotTimestamp = 0;
    lastAliveSignalTimestamp = 0;
    shotsToFinish = 0;
    timeToFinish = 0;

    ball = null;
    mainLine = null;
    forceLine = null;
    controls = null;
    playerText = null;
    playerTextFinished = null;

    angle = Player.PARAMS.ANGLE.initial;
    force = Player.PARAMS.FORCE.initial;
    forceDirection = 1;
    lastPositions = [];
    status = "STANDING"; // STANDING, LOADING_SHOT, FLYING, MAYBE_STANDING, FINISHED, AFK, DISCONNECTED

    constructor(playerData, isLocalPlayer) {
        this.id = playerData.id;
        this.name = playerData.n;
        this.color = playerData.c;
        this.points = playerData.p || 0;
        this.pointsDelta = playerData.pd || 0;
        this.lastAliveSignalTimestamp = playerData.t;
        this.isLocalPlayer = !!isLocalPlayer;

        const matter = GLOBALS.SCENE.matter;
        this.ballImage = matter.add.image(0,0, 'ball');
        this.ballImage.setCircle();
        this.ballImage.setInteractive();
        this.ballImage.setDisplaySize(Player.PARAMS.SIZE, Player.PARAMS.SIZE);
        this.ballImage.setFriction(Player.PARAMS.FRICTION);
        this.ballImage.setFrictionAir(Player.PARAMS.FRICTION_AIR);
        this.ballImage.setFrictionStatic(Player.PARAMS.FRICTION_STATIC);
        this.ballImage.setBounce(Player.PARAMS.BOUNCE);
        this.ballImage.setMass(Player.PARAMS.MASS);
        this.ballImage.setTint(Phaser.Display.Color.ValueToColor(this.color).color);
        this.ball = this.ballImage.body;
        this.ball.inertia = Player.PARAMS.INERTIA;
        this.ball.timeScale = Player.PARAMS.TIMESCALE;
        this.ball.collisionFilter.category = 2;
        this.ball.collisionFilter.mask = 1;

        if (this.isLocalPlayer) {
            this.mainLine = new Phaser.Geom.Line();
            this.forceLine = new Phaser.Geom.Line();
            this.initControls();
            if (GLOBALS.TOUCH_INPUT_ENABLED)
                this.initTouchControls();
        }

        const playerTextStyle = {font: Player.PARAMS.FONT_SIZE + "px MainFont", fill: this.color};
        this.playerText = GLOBALS.SCENE.add.text(0, 0, "", playerTextStyle).setOrigin(0, 0);
        this.playerText.setStroke('#333333', 2);
        this.playerTextFinished = GLOBALS.SCENE.add.text(0, 0, "", playerTextStyle).setOrigin(1, 0);
        this.playerTextFinished.setStroke('#333333', 2);

        this.reset();
    }

    reset() {
        this.resetPosition();
        this.status = "STANDING";
        this.shotsToFinish = 0;
        this.playerTextFinished?.setText("");
        this.lastPositions = [];
        this.dirty = true;
    }

    resetPosition() {
        let spawnPosition = new UTIL.V2(GLOBALS.WORLD_WIDTH/2, GLOBALS.WORLD_HEIGHT/2);
        if (GLOBALS.SCENE.level?.getSpawnPosition()) {
            spawnPosition = new UTIL.V2(GLOBALS.SCENE.level.getSpawnPosition()).add(new UTIL.V2(0, -Player.PARAMS.SIZE/2));
        }
        this.angle = Player.PARAMS.ANGLE.initial;

        GLOBALS.SCENE.matter.body.setPosition(this.ball, spawnPosition);
        GLOBALS.SCENE.matter.body.setVelocity(this.ball, {x: 0, y: 0});
        GLOBALS.SCENE.matter.body.setAngularVelocity(this.ball, 0);

        this.dirty = true;
    }

    initControls() {
        this.controls = {};
        document.addEventListener("keyup", e => {
            switch(e.key) {
                case " ": // SPACE
                    this.controls.shootPressed = false;
                    e.preventDefault();
                    break;
                case "a":
                case "A":
                case "ArrowLeft":
                    this.controls.steerLeftPressed = false;
                    e.preventDefault();
                    break;
                case "d":
                case "D":
                case "ArrowRight":
                    this.controls.steerRightPressed = false;
                    e.preventDefault();
                    break;
                default:
                    break;
            }
        }, false);
        document.addEventListener("keydown", e => {
            switch(e.key) {
                case " ":
                    this.controls.shootPressed = true;
                    e.preventDefault();
                    break;
                case "a":
                case "A":
                case "ArrowLeft":
                    this.controls.steerLeftPressed = true;
                    e.preventDefault();
                    break;
                case "d":
                case "D":
                case "ArrowRight":
                    this.controls.steerRightPressed = true;
                    e.preventDefault();
                    break;
                default:
                    break;
            }
        }, false);

        document.body.click();
    }

    initTouchControls() {
        document.getElementById("steerLeftButton").addEventListener("touchstart", () => this.controls.steerLeftPressed = true);
        document.getElementById("steerLeftButton").addEventListener("touchend", (evt) => {evt.preventDefault(); this.controls.steerLeftPressed = false;});
        document.getElementById("steerRightButton").addEventListener("touchstart", () => this.controls.steerRightPressed = true);
        document.getElementById("steerRightButton").addEventListener("touchend", (evt) => {evt.preventDefault(); this.controls.steerRightPressed = false;});
        document.getElementById("shootButton").addEventListener("touchstart", () => this.controls.shootPressed = true);
        document.getElementById("shootButton").addEventListener("touchend", (evt) => {evt.preventDefault(); this.controls.shootPressed = false; UTIL.startFullscreen();});
    }

    update(time, delta) {
        if (this.ball.position.x < 0 || this.ball.position.x > GLOBALS.WORLD_WIDTH)
            this.resetPosition();

        if (this.status == "STANDING" && GLOBALS.SCENE.status == "PLAYING" && this.isBallInHole(this.ball)) {
            this.status = "FINISHED";
            if (this.isLocalPlayer) {
                GLOBALS.MPCONTROLLER.sendFinished(this.shotsToFinish, GLOBALS.SCENE.roundNumber);
            }
        }

        if (this.isLocalPlayer) {
            this.checkStandingStillStatus();

            this.checkControls(delta);

            if (this.canShoot()) {
                Phaser.Geom.Line.SetToAngle(this.mainLine, this.ball.position.x, this.ball.position.y, this.angle, GLOBALS.WORLD_HEIGHT / 10);
                const startPoint = this.mainLine.getPoint(0.2);
                const endPoint = this.mainLine.getPointB();
                this.mainLine.setTo(startPoint.x, startPoint.y, endPoint.x, endPoint.y);

                this.forceLine.setTo(startPoint.x, startPoint.y, endPoint.x, endPoint.y);
                const newEndPoint = this.forceLine.getPoint(this.force);
                this.forceLine.setTo(startPoint.x, startPoint.y, newEndPoint.x, newEndPoint.y);

                GLOBALS.SCENE.graphics.lineStyle(2, 0xffffff, 0.8);
                GLOBALS.SCENE.graphics.strokeLineShape(this.mainLine);
                GLOBALS.SCENE.graphics.lineStyle(4, 0xff0000, 0.5);
                GLOBALS.SCENE.graphics.strokeLineShape(this.forceLine);
            }

            if (this.status == "LOADING_SHOT") {
                this.force += delta * Player.PARAMS.FORCE.change_speed_factor * this.forceDirection;
                if (this.force > 1)
                    this.forceDirection = -1;
                if (this.force < 0)
                    this.forceDirection = 1;
                UTIL.clamp(this.force, 0, 1);
            }
        }
    }

    checkControls(delta) {
        if (this.status == "STANDING" && this.controls.shootPressed)
            this.startShooting();
        if (this.status == "LOADING_SHOT" && !this.controls.shootPressed)
            setTimeout(() => this.shoot(), 1);
        if (this.controls.steerLeftPressed)
            this.updateSteering(-1, delta);
        if (this.controls.steerRightPressed)
            this.updateSteering(1, delta);
    }

    updateSteering(direction, delta) {
        this.angle = UTIL.clamp(this.angle + direction*Player.PARAMS.ANGLE.change_speed*delta, Player.PARAMS.ANGLE.min, Player.PARAMS.ANGLE.max);
    }

    isCurrentlyStandingStill() {
        const n = this.lastPositions.length-1;
        const n2 = Math.floor(this.lastPositions.length/2);
        const xIsSmall = Math.abs(this.lastPositions[n].x - this.lastPositions[n2].x) < Player.PARAMS.STANDING_CHECK.thresholdHalf
            && Math.abs(this.lastPositions[n].x - this.lastPositions[0].x) < Player.PARAMS.STANDING_CHECK.thresholdFull;
        const yIsSmall = Math.abs(this.lastPositions[n].y - this.lastPositions[n2].y) < Player.PARAMS.STANDING_CHECK.thresholdHalf
            && Math.abs(this.lastPositions[n].y - this.lastPositions[0].y) < Player.PARAMS.STANDING_CHECK.thresholdFull;
        return xIsSmall && yIsSmall;
    }

    canShoot() {
        return (this.status == "STANDING" || this.status == "LOADING_SHOT") && GLOBALS.SCENE.status == "PLAYING";
    }

    startShooting() {
        if (this.canShoot()) {
            this.status = "LOADING_SHOT";
        }
    }

    shoot(force) {
        if (this.status == "LOADING_SHOT" || force) {
            this.shotsToFinish++;
            const forceVector = new UTIL.V2(Math.cos(this.angle), Math.sin(this.angle));
            forceVector.normalize();
            if (!force) {
                const invertedForceMax = Math.pow(Player.PARAMS.FORCE.max, 1/Player.PARAMS.FORCE.pow);
                forceVector.scale(Math.pow(this.force * invertedForceMax, Player.PARAMS.FORCE.pow));
            } else
                forceVector.scale(force);

            GLOBALS.SCENE.matter.applyForce(this.ball, forceVector);

            this.status = "FLYING";
            this.force = Player.PARAMS.FORCE.initial;
            this.forceDirection = 1;
            this.dirty = true;
        }
    }

    checkStandingStillStatus() {
        const now = Date.now();
        this.lastPositions.push({t: now, x: this.ball.position.x, y: this.ball.position.y});
        if (this.lastPositions[0].t < now - Player.PARAMS.STANDING_CHECK.time)
            this.lastPositions.splice(0,1);

        const standingStill = this.isCurrentlyStandingStill();
        if (this.status.indexOf("MAYBE_STANDING") > -1) {
            const number = this.status.substr(this.status.length-1,1);
            if (standingStill)
                this.status = "MAYBE_STANDING" + (parseInt(number)+1);
            else
                this.status = "FLYING";
        }

        if (this.status == "FLYING" && standingStill) {
            this.status = "MAYBE_STANDING1";
            this.dirty = true;
        }
        if (this.status == "MAYBE_STANDING4") {
            this.status = "STANDING";
            this.angle = this.ball.position.x < GLOBALS.SCENE.level.levelData.holePosition.x ? Player.PARAMS.ANGLE.initial : Player.PARAMS.ANGLE.initialReverse;
            this.dirty = true;
        }
    }

    isBallInHole() {
        const level = GLOBALS.SCENE.level;
        if (!level) return false;

        const holeRect = level.getHoleRect();
        return Phaser.Geom.Rectangle.ContainsPoint(holeRect, this.ball.position);
    }

    getLastShotData() {
        return {p: this.ball.position, v: this.ball.velocity, av: this.ball.angularVelocity, s: this.shotsToFinish};
    }

    setState(state) {
        if (!state) return;
        this.points = state.p || 0;
        this.pointsDelta = state.pd || 0;

        if (state.fin && state.fin.s > 0 && state.fin.t > GLOBALS.SCENE.level.levelData.t && state.fin.r == GLOBALS.SCENE.roundNumber)
            this.setFinished(state.fin.s, state.fin.t);

        if (!this.isLocalPlayer) {
            if (state.ls && this.lastShotTimestamp + 10 < state.ls.t) {
                if (this.status != "FINISHED" && GLOBALS.SCENE.getPlayersAwakeCount() < Player.PARAMS.MAX_PHYSICS_PLAYERS)
                    this.ballImage.setAwake();

                GLOBALS.SCENE.matter.body.setPosition(this.ball, state.ls.p);
                GLOBALS.SCENE.matter.body.setVelocity(this.ball, state.ls.v);
                GLOBALS.SCENE.matter.body.setAngularVelocity(this.ball, state.ls.av);
                this.lastShotTimestamp = state.ls.t;
            }
        }
    }

    setLastShotTimestamp(timestamp) {
        this.lastShotTimestamp = timestamp;
    }
    setLastAliveSignalTimestamp(timestamp) {
        this.lastAliveSignalTimestamp = timestamp;
    }

    setFinished(shots, time) {
        this.status = "FINISHED";
        this.shotsToFinish = shots;
        this.timeToFinish = time;
    }

    setActive() {
        if (this.isInactive())
            this.status = "STANDING";
    }

    setInactive(disconnected) {
        if (this.status != "FINISHED")
            this.status = disconnected ? "DISCONNECTED" : "AFK";
    }

    isInactive() {
        return this.status == "AFK" || this.status == "DISCONNECTED";
    }

    dispose() {
        this.playerText.destroy();
        this.playerTextFinished.destroy();
        this.ballImage.destroy();
        GLOBALS.SCENE.matter.world.remove(this.ball);
    }

    updatePlayerText(rank) {
        const endOfSomething = GLOBALS.SCENE.status.indexOf("END_OF") > -1;
        const x = endOfSomething ? GLOBALS.WORLD_WIDTH/2 : 2;
        const originX = endOfSomething ? 0.5 : 0;
        const y = (endOfSomething ? 80 : 2) + rank * Player.PARAMS.FONT_SIZE;

        this.playerText.setPosition(x, y);
        this.playerText.setOrigin(originX, 0);
        const statusText = endOfSomething ? "" : (Player.statusEmojiMap[this.status] || "");
        const pointsDeltaText = this.pointsDelta && GLOBALS.SCENE.showPointsDelta() ? "+" +(this.pointsDelta || 0) + " = " : "";
        this.playerText.setText((rank+1) + ". " + pointsDeltaText + (this.points || 0) + " points " + this.name + " " + statusText);
    }

    updatePlayerTextFinished(rank) {
        this.playerTextFinished.setPosition(GLOBALS.WORLD_WIDTH - 2, rank*Player.PARAMS.FONT_SIZE + 2);
        this.playerTextFinished.setText(this.name + " finished in " + (Math.round((this.timeToFinish - GLOBALS.SCENE.level.levelData.t)/100)/10) + " s with " + this.shotsToFinish + " shots!");
    }

    resetPlayerTextFinished() {
        this.playerTextFinished.setText("");
    }

    hasFinished() {
        return this.status == "FINISHED" && this.shotsToFinish > 0 && this.timeToFinish > GLOBALS.SCENE.level.levelData.t;
    }

    static compare(a, b) {
        let scoreA = a.points;
        if (a.status == "DISCONNECTED") scoreA -= 100000;
        let scoreB = b.points;
        if (b.status == "DISCONNECTED") scoreB -= 100000;

        if (scoreA == scoreB)
            return a.id > b.id ? -1 : 1;

        return scoreA > scoreB ? -1 : 1;
    }

    static compareFinished(a, b) {
        if (a.shotsToFinish == b.shotsToFinish)
            return a.timeToFinish < b.timeToFinish ? -1 : 1;
        return a.shotsToFinish < b.shotsToFinish ? -1 : 1;
    }
}