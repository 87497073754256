import "phaser";
import MultigolfScene from "./MultigolfScene";
import MultiplayerController from "./MultiplayerController";

window.UTIL = {
    randomInt: (a,b) => Phaser.Math.RND.integerInRange(a,b),
    randomReal: (a,b) => Phaser.Math.RND.realInRange(a,b),
    randomColor: () => '#'+Math.random().toString(16).substr(2,6),
    randomString: (length) => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length),
    shuffle: (a) => Phaser.Math.RND.shuffle(a),
    clamp: Phaser.Math.Clamp,
    V2: Phaser.Math.Vector2,
    startFullscreen: () => {
        const element = document.body;
        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        }
    }
};

window.GLOBALS = {VERSION: "1.03", WORLD_WIDTH: 1000, WORLD_HEIGHT: 600, TOUCH_INPUT_ENABLED: 'ontouchstart' in document.documentElement};
window.init = () => {
    document.getElementById("version").innerHTML = GLOBALS.VERSION;
    document.getElementById("PlayerColor").value = UTIL.randomColor();

    const config = {
        type: Phaser.AUTO,
        width: GLOBALS.WORLD_WIDTH,
        height: GLOBALS.WORLD_HEIGHT,
        physics: {
            default: "matter",
            matter: {
                gravity: {
                    y: 0.6
                },
                positionIterations: 4,
                velocityIterations: 3,
                enableSleeping: true,
                autoUpdate: false,
                plugins: {
                    collisionevents: false
                },
                debug: {
                    showBody: false,
                    staticLineColor: "0xa16a40"
                }
            }
        },
        scene: MultigolfScene,
        parent: "back"
    };

    GLOBALS.LOCAL_PLAYER_ID = localStorage.getItem("LOCAL_PLAYER_ID") || UTIL.randomString(6);
    GLOBALS.GAME = new Phaser.Game(config);
    GLOBALS.MPCONTROLLER = new MultiplayerController();
    setTimeout(window.initMainMenu, 1);
};

window.initMainMenu = function() {
    const playerNameEl = document.getElementById("PlayerName");
    const playerColorEl = document.getElementById("PlayerColor");
    const roomCodeEl = document.getElementById("RoomCode");

    const playerNameFromStorage = localStorage.getItem("PlayerName");
    if (playerNameFromStorage && playerNameEl)
        playerNameEl.value = playerNameFromStorage;
    const playerColorFromStorage = localStorage.getItem("PlayerColor");
    if (playerColorFromStorage && playerColorEl)
        playerColorEl.value = playerColorFromStorage;

    let roomCodeFromStorage = localStorage.getItem("RoomCode");
    const roomIndex = window.location.search.indexOf("room=");
    if (roomIndex > -1) {
        let roomString = window.location.search.substr(roomIndex+5).split("&")[0];
        if (roomString.length > 0)
        roomCodeFromStorage = roomString;
    }

    if (roomCodeFromStorage && roomCodeEl)
        roomCodeEl.value = roomCodeFromStorage;

    window.fitMainMenuHeight();
    window.addEventListener('resize', window.fitMainMenuHeight);

    if (GLOBALS.TOUCH_INPUT_ENABLED) {
        document.getElementById("touchInput").classList.remove("hidden");
        document.getElementById("howToShoot").innerHTML = "middle area of game";
        document.getElementById("howToSteer").innerHTML = "left and right area of game";
    }
};

window.fitMainMenuHeight = function() {
    const canvasEl = document.querySelector("#back canvas");
    const frontEl = document.getElementById("front");
    const backEl = document.getElementById("back");
    if (canvasEl && frontEl && canvasEl.offsetHeight > 100) {
        frontEl.style.height = Math.max(canvasEl.offsetHeight, 200) + "px";
        if (backEl)
            backEl.style.height = Math.max(canvasEl.offsetHeight, 200) + "px";
    } else {
        setTimeout(window.fitMainMenuHeight, 50);
    }
};

window.joinGame = function() {
    let roomCode = document.getElementById("RoomCode").value;
    const playerName = document.getElementById("PlayerName").value;
    const playerColor = document.getElementById("PlayerColor").value;
    if (roomCode && playerName) {
        roomCode = roomCode.trim().toUpperCase().replace(/([$.#\[\]/&])/g, "");
        GLOBALS.MPCONTROLLER.joinGame(roomCode, playerName, playerColor);
        if (window.history.pushState)
            window.history.pushState(null,"", window.location.origin + window.location.pathname + "?room=" + roomCode);

        const mainContainer = document.getElementById("mainContainer");
        mainContainer.classList.add("gameStarted");

        localStorage.setItem("LOCAL_PLAYER_ID", GLOBALS.LOCAL_PLAYER_ID);
        localStorage.setItem("PlayerName", playerName);
        localStorage.setItem("PlayerColor", playerColor);
        localStorage.setItem("RoomCode", roomCode);
        if (GLOBALS.TOUCH_INPUT_ENABLED)
            UTIL.startFullscreen();
    } else {
        alert("Please enter " + (roomCode ? "Name" : "Roomcode"));
    }
};

document.addEventListener("DOMContentLoaded", init);
