export default class MultiplayerController {
    database = null;
    currentGamePlayersRef = null;
    localPlayerRef = null;
    serverTimeOffset = 0;
    lastTimeLevelSent = 0;

    constructor() {
        this.database = firebase.database();

        // Sync Server and Local Time
        this.database.ref(".info/serverTimeOffset").on("value", snapshot => {
            this.serverTimeOffset = snapshot.val();
        });
    }

    updatePlayer(player, newState) {
        player.setState(newState);
        if (newState?.t)
            player.setLastAliveSignalTimestamp(newState?.t);
    }

    joinGame(roomCode, playerName, playerColor) {
        this.currentGameRef = this.database.ref("/games/" + roomCode);
        this.currentGameLevelRef = this.currentGameRef.child("level");
        this.currentGamePlayersRef = this.currentGameRef.child("players");

        // LevelData ChangeListener
        this.currentGameLevelRef.on('value', snapshot => {
            const newLevelData = snapshot.val();
            if (newLevelData) {
                GLOBALS.SCENE.startNewRound(newLevelData);
            }
        });

        // Players added listener
        this.currentGamePlayersRef.on('child_added', snapshot => {
            const newPlayerData = snapshot.val();
            if (newPlayerData?.data) {
                const addedPlayer = GLOBALS.SCENE.addPlayer(newPlayerData.data);
                this.updatePlayer(addedPlayer, newPlayerData);
            }
        });

        this.currentGamePlayersRef.on('child_removed', snapshot => {
            console.log("CHILD REMOVED: " + snapshot.key);
            GLOBALS.SCENE.removePlayer(snapshot.key);
        });

        // Input-Data Listener
        this.currentGamePlayersRef.on('child_changed', snapshot => {
            const newState = snapshot.val();
            const allPlayerData = GLOBALS.SCENE.getPlayers();
            const changedPlayer = allPlayerData.find(player => player.id == newState.data.id);
            if (!changedPlayer)
                return;

            this.updatePlayer(changedPlayer, newState);
        });


        this.localPlayerRef = this.currentGamePlayersRef.child(GLOBALS.LOCAL_PLAYER_ID);
        this.localPlayerRef.child("data").set({id: GLOBALS.LOCAL_PLAYER_ID, n: playerName, c: playerColor, t: firebase.database.ServerValue.TIMESTAMP});
        setInterval(() => this.sendAliveSignal(), 4000 + UTIL.randomInt(0, 500)); // Keep alive Signal
        setInterval(() => this.checkForInactivePlayers(), 1000);
    }

    checkForInactivePlayers() {
        const allPlayerData = GLOBALS.SCENE.getPlayers();
        allPlayerData.forEach(player => {
            if (!player.isLocalPlayer) {
                if (player.lastAliveSignalTimestamp < this.nowInServerTime() - 20000)
                    player.setInactive(true);
                else if (player.lastShotTimestamp < this.nowInServerTime() - 12000)
                    player.setInactive();
                else
                    player.setActive();
            }
        });
    }

    sendLocalState(currentLocalState) {
        currentLocalState.t = firebase.database.ServerValue.TIMESTAMP;
        this.localPlayerRef.child("ls").set(currentLocalState);
    }

    sendFinished(shots, roundNumber) {
        this.localPlayerRef.child("fin").set({t:firebase.database.ServerValue.TIMESTAMP, s: shots, r: roundNumber});
    }

    sendAliveSignal() {
        this.localPlayerRef.update({t: firebase.database.ServerValue.TIMESTAMP});
    }

    sendComputeScores() {
        console.log("SENDING computeScores");
        this.currentGameRef.update({computeScores: firebase.database.ServerValue.TIMESTAMP});
    }
    sendCreateNextLevel() {
        console.log("SENDING createNextLevel");
        this.currentGameRef.update({createNextLevel: firebase.database.ServerValue.TIMESTAMP});
    }

    serverTimeToLocalTime(serverTimestamp) {
        return serverTimestamp - this.serverTimeOffset;
    }

    localTimeToServerTime(givenTime) {
        return givenTime + this.serverTimeOffset;
    }

    nowInServerTime() {
        return this.localTimeToServerTime(new Date().getTime());
    }
}