export default class Level {
    static PARAMS = {
        SEGMENTS: {
            minWidth: 24,
            maxWidth: 100
        },
        POINTY: {
            totalRange: 250,
            singleRange: 60
        },
        HOLE: {
            WIDTH: 16,
            HEIGHT: 20
        }
    };
    levelData = {};
    terrain = null;

    constructor(levelData) {
        const matter = GLOBALS.SCENE.matter;

        this.levelData = this.isValidLevelData(levelData) ? levelData : Level.calculateLevelData();

        this.terrain = matter.add.fromVertices(0, 0, this.levelData.vertices, { isStatic: true }, true, 0.01, 10);
        this.terrain.collisionFilter.category = 1;
        this.terrain.collisionFilter.mask = 2;
        this.terrain.label = "TERRAIN";

        matter.alignBody(this.terrain, GLOBALS.WORLD_WIDTH/2, GLOBALS.WORLD_HEIGHT, Phaser.Display.Align.BOTTOM_CENTER);
    }

    /**
     * Calculates vertices for Level, spawnPosition, holePosition in World-Coordinates.
     * @returns {{spawnPosition: V2, holePosition: V2, vertices: []}}
     */
    static calculateLevelData() {
        const WW = GLOBALS.WORLD_WIDTH;
        const WH = GLOBALS.WORLD_HEIGHT;

        const terrainPoints = [];

        const segmentWidths = Level.calculateSegmentWidths(WW, Level.PARAMS.SEGMENTS.minWidth, Level.PARAMS.SEGMENTS.maxWidth);
        const numberOfSegments = segmentWidths.length;
        const spawnSegment = UTIL.randomInt(0, numberOfSegments/4);
        const spawnPosition = new UTIL.V2(0, 0);
        const holeSegment = UTIL.randomInt(numberOfSegments*3/4, numberOfSegments-2);
        const holePosition = new UTIL.V2(0, 0);

        const maxBound = WH - Level.PARAMS.HOLE.HEIGHT - 10;
        const minBound = maxBound - Level.PARAMS.POINTY.totalRange;

        let currentX = 0;
        let currentY = UTIL.randomInt(minBound, maxBound);
        terrainPoints.push(new UTIL.V2(currentX, currentY));
        for (let i=0; i < numberOfSegments; i++) {
            if (i == spawnSegment) {
                spawnPosition.x = currentX + UTIL.randomInt(segmentWidths[i] / 3, segmentWidths[i] / 2);
                spawnPosition.y = currentY;
            } else if (i == holeSegment) {
                holePosition.x = currentX + UTIL.randomInt(segmentWidths[i] * 1/3, segmentWidths[i] * 2/3);
                holePosition.x = UTIL.clamp(holePosition.x, currentX + Level.PARAMS.HOLE.WIDTH/2 + 1, currentX + segmentWidths[i] - Level.PARAMS.HOLE.WIDTH/2 - 1);
                holePosition.y = currentY + Level.PARAMS.HOLE.HEIGHT;

                terrainPoints.push(new UTIL.V2(holePosition.x-Level.PARAMS.HOLE.WIDTH/2, currentY));
                terrainPoints.push(new UTIL.V2(holePosition.x-Level.PARAMS.HOLE.WIDTH/2, currentY + Level.PARAMS.HOLE.HEIGHT*0.8));
                terrainPoints.push(new UTIL.V2(holePosition.x, currentY + Level.PARAMS.HOLE.HEIGHT));
                terrainPoints.push(new UTIL.V2(holePosition.x+Level.PARAMS.HOLE.WIDTH/2, currentY + Level.PARAMS.HOLE.HEIGHT*0.8));
                terrainPoints.push(new UTIL.V2(holePosition.x+Level.PARAMS.HOLE.WIDTH/2, currentY));
            }

            currentX += segmentWidths[i];

            // Change height only if (keep flat on spawn, hole and random segments)
            if (UTIL.randomInt(1,3) != 1 && i != spawnSegment && i != holeSegment) {
                let currentMinBound = currentY - Level.PARAMS.POINTY.singleRange;
                let currentMaxBound = currentY + Level.PARAMS.POINTY.singleRange;

                if (currentMinBound < minBound) {
                    currentMinBound = minBound;
                    currentMaxBound += minBound - currentMinBound;
                }
                if (currentMaxBound > maxBound) {
                    currentMaxBound = maxBound;
                    currentMinBound -= currentMaxBound - maxBound;
                }

                currentY = UTIL.randomInt(currentMinBound, currentMaxBound);
            }

            terrainPoints.push(new UTIL.V2(currentX, currentY));
        }
        terrainPoints.push(new UTIL.V2(WW, WH));
        terrainPoints.push(new UTIL.V2(0, WH));

        const result = {spawnPosition: spawnPosition, holePosition: holePosition, vertices: terrainPoints};
        result.hash = this.calcLevelDataHash(result);
        return result;
    }

    static calculateSegmentWidths(totalWidth, minWidth, maxWidth) {
        const widths = [];
        let currentX = 0;

        while (currentX < totalWidth) {
            let remainingWidth = totalWidth - currentX;
            let width = remainingWidth < maxWidth ? remainingWidth : UTIL.randomInt(minWidth, maxWidth);
            widths.push(width);
            currentX += width;
        }

        return UTIL.shuffle(widths);
    }

    static calcLevelDataHash(levelData) {
        return (levelData.vertices.reduce((sum, vertex) => {sum += vertex.y; return sum;}, 0) + levelData.spawnPosition.y + levelData.holePosition.y) % 9999;
    }

    getSpawnPosition() {
        return this.levelData.spawnPosition;
    }

    isValidLevelData(levelData) {
        return levelData && levelData.vertices && levelData.spawnPosition && levelData.holePosition;
    }

    getHoleRect() {
        const hp = this.levelData.holePosition;
        const w = Level.PARAMS.HOLE.WIDTH;
        const h = Level.PARAMS.HOLE.HEIGHT;
        return new Phaser.Geom.Rectangle(hp.x-w/2, hp.y-h, w, h);
    }

    getTimeElapsed() {
        return new Date().getTime() - GLOBALS.MPCONTROLLER.serverTimeToLocalTime(this.levelData.t);
    }

    dispose() {
        GLOBALS.SCENE.matter.world.remove(this.terrain);
    }
}