export default class Fireworks {
    static PARAMS = {
        x: 200,
        y: 100,
        speed: 400,
        scale0: 0.15,
        scale1: 0.07,
        lifespan: 1000,
        gravity: 800
    };

    constructor(scene) {
        const emitShape = new Phaser.Geom.Rectangle(-Fireworks.PARAMS.x/2, 0, Fireworks.PARAMS.x, 6);

        const emitterConfigL = {
            x: Fireworks.PARAMS.x,
            y: Fireworks.PARAMS.y,
            speed: { min: -Fireworks.PARAMS.speed, max: Fireworks.PARAMS.speed },
            alpha: { min: 0.75, max: 1 },
            angle: { min: 210, max: 330 },
            scale: { min: Fireworks.PARAMS.scale1, max: Fireworks.PARAMS.scale0, start: Fireworks.PARAMS.scale0, end: 0 },
            emitZone: { type: 'random', source: emitShape },
            frequency: 0.1,
            lifespan: Fireworks.PARAMS.lifespan,
            gravityY: Fireworks.PARAMS.gravity,
            blendMode: 'SOFT_LIGHT'
        };

        const emitterConfigR = {};
        Object.assign(emitterConfigR, emitterConfigL);
        emitterConfigR.x = 1000 - emitterConfigL.x;

        const particle = scene.add.particles('spark');
        const well = particle.createGravityWell({
            x: 500,
            y: 300,
            power: 1,
            epsilon: 500,
            gravity: 600
        });
        this.emitters = [
            particle.createEmitter(emitterConfigL),
            particle.createEmitter(emitterConfigR),
        ];
        this.stop();
    }

    start(color) {
        const phaserColor0 = Phaser.Display.Color.ValueToColor(color);
        const phaserColor1 = phaserColor0.clone().brighten(10);
        const phaserColor2 = phaserColor0.clone().saturate(10);
        const tintArray = [phaserColor0.color, phaserColor1.color, phaserColor2.color];
        this.emitters.forEach(emitter => {
            emitter.setTint(tintArray);
            emitter.start();
        });
    }

    stop() {
        this.emitters.forEach(emitter => emitter.stop());
    }
}