import sky from "../assets/sky.png";
import ground from "../assets/ground.png";
import ball from "../assets/ball.png";
import flag from "../assets/flag.png";
import spark from "../assets/spark.png";
import Player from "./Player";
import Level from "./Level";
import Fireworks from "./Fireworks";

export default class MultigolfScene extends Phaser.Scene {
    static PARAMS = {
        ROUNDTIME: 65,
        START_OF_ROUND_TIME: 4,
        END_OF_ROUND_TIME: 4,
        END_OF_GAME_TIME: 20,
        SHOW_POINTSDELTA_TIME: 5,
        PHYSICS_TIMESTEP: 1000/60
    };

    status = "START_OF_ROUND";
    players = [];
    localPlayer = null;
    level = null;
    roundNumber = 1;
    maxRoundNumber = 10;

    matter = null;
    fpsText = null;
    roundText = null;
    timeLeftText = null;
    flagImage = null;
    flagText = null;
    groundImage = null;
    physicsStepAccumulator = 0;
    fireworks = null;

    constructor() {
        super({key:'MultigolfScene'});
        GLOBALS.SCENE = this;
    }

    preload() {
        this.load.image('sky', sky);
        this.load.image('ground', ground);
        this.load.image('ball', ball);
        this.load.image('flag', flag);
        this.load.image('spark', spark);
    }

    create() {
        // Background Image
        this.add.image(GLOBALS.WORLD_WIDTH/2, GLOBALS.WORLD_HEIGHT/2, 'sky').scaleX = GLOBALS.WORLD_WIDTH;

        this.graphics = this.add.graphics({ lineStyle: { width: 8, color: 0xaa0077, alpha: 0.5 } });

        this.initLevelGraphics();

        const fpsStyle = { font: "bold 16px MainFont", fill: "#ab8c71" };
        this.fpsText = this.add.text(3, GLOBALS.WORLD_HEIGHT-1, "_", fpsStyle).setOrigin(0, 1);
        const style = { font: "bold 32px MainFont", fill: "#ffffff" };
        this.roundText = this.add.text(GLOBALS.WORLD_WIDTH/2, 2, "_", style).setOrigin(0.5, 0);
        this.roundText.setStroke('#555555', 8);
        this.timeLeftText = this.add.text(GLOBALS.WORLD_WIDTH/2, 36, "_", style).setOrigin(0.5, 0);
        this.timeLeftText.setStroke('#555555', 8);
        this.fireworks = new Fireworks(this, "#c92cff");
    }

    update(time, delta) {
        this.updatePhysics(delta);

        this.graphics.clear();
        this.players.forEach(player => player.update(time, delta));

        this.updatePlayerTexts();

        this.checkEndOfRound();
        this.updateInfoTexts();
    }

    updatePhysics(delta) {
        this.physicsStepAccumulator += delta;
        let physicsStepsThisUpdate = 0;
        while(this.physicsStepAccumulator >= MultigolfScene.PARAMS.PHYSICS_TIMESTEP) {
            this.physicsStepAccumulator -= MultigolfScene.PARAMS.PHYSICS_TIMESTEP;
            this.matter.world.step(MultigolfScene.PARAMS.PHYSICS_TIMESTEP);
            physicsStepsThisUpdate++;

            if (this.localPlayer?.dirty) {
                GLOBALS.MPCONTROLLER?.sendLocalState(this.localPlayer.getLastShotData());
                this.localPlayer.dirty = false;
            }
        }
        this.fpsTooLowForPhysics = physicsStepsThisUpdate > 2
    }

    checkEndOfRound() {
        if (this.status == "PLAYING" && GLOBALS.MPCONTROLLER && this.level) {
            const timeElapsed = this.level.getTimeElapsed();
            const timeLeft = Math.floor(MultigolfScene.PARAMS.ROUNDTIME - timeElapsed / 1000);
            const allFinished = !this.players.some(player => !player.hasFinished());
            if (timeLeft < 0 || allFinished) {
                console.log("END OF ROUND, timeLeft: " + timeLeft + " roundNumber " + this.roundNumber + " hash: " + this.level.levelData.hash);
                this.setStatus("WAITING_FOR_SCORES");
                this.players.forEach(player => player.pointsDelta = 0);
                const endOfGame = this.roundNumber >= this.maxRoundNumber;
                const timeToNewRound = (endOfGame ? MultigolfScene.PARAMS.END_OF_GAME_TIME : MultigolfScene.PARAMS.END_OF_ROUND_TIME) * 1000;
                setTimeout(() => {
                    GLOBALS.MPCONTROLLER.sendComputeScores();

                    setTimeout(() => {
                        this.setStatus(endOfGame ? "END_OF_GAME" : "END_OF_ROUND");
                        if (endOfGame) {
                            const sortedPlayers = this.players.sort(Player.compare);
                            this.fireworks.start(sortedPlayers[0].color);
                        }
                    }, 100);

                    setTimeout(() => {
                        GLOBALS.MPCONTROLLER.sendCreateNextLevel();
                    }, timeToNewRound);
                }, 1000);
            }
        }
    }

    startNewRound(levelData) {
        this.fireworks.stop();
        this.level?.dispose();

        console.log("NEW ROUND roundNumber: " + levelData.round.current + " hash: " + levelData.data.hash);
        this.roundNumber = levelData.round.current || 1;
        this.maxRoundNumber = levelData.round.max || 10;

        this.level = new Level(levelData.data);
        this.updateLevelGraphics(this.level.levelData);
        this.players.forEach((player, index) => player.reset(index));
        this.setStatus("START_OF_ROUND");

        const timeToStart = Math.max(MultigolfScene.PARAMS.START_OF_ROUND_TIME * 1000 - this.level.getTimeElapsed(), 0);
        console.log("status = START_OF_ROUND, timeToStart: " + timeToStart);
        setTimeout(() => this.setStatus("PLAYING"), timeToStart);
    }

    initLevelGraphics() {
        this.flagImage = this.add.image(0, 0, 'flag').setOrigin(0.5, 1);
        const flagTextStyle = { font: "bold 20px MainFont", fill: "#ffffff" };
        this.flagText = this.add.text(0, 0, "", flagTextStyle).setOrigin(0.5, 0.5);

        this.groundImage = this.add.image(0, GLOBALS.WORLD_HEIGHT, 'ground').setOrigin(0, 1);

        const shape = this.make.graphics();
        shape.beginPath();
        shape.fillRect(0,0,1,1);
        const mask = shape.createGeometryMask();
        this.groundImage.setMask(mask);
    }

    updateLevelGraphics(levelData) {
        const holePos = levelData.holePosition;
        this.flagImage.setPosition(holePos.x, holePos.y);
        this.flagText.setPosition(holePos.x-13, holePos.y-86);
        this.flagText.setText(this.roundNumber);

        const shape = this.make.graphics();
        shape.beginPath();
        shape.fillPoints(levelData.vertices);
        const mask = shape.createGeometryMask();
        this.groundImage.setMask(mask);
    }

    showPointsDelta() {
        return (this.level.getTimeElapsed() < MultigolfScene.PARAMS.SHOW_POINTSDELTA_TIME * 1000) || (this.status != "PLAYING" && this.status != "WAITING_FOR_SCORES");
    }

    setStatus(status) {
        this.status = status;
    }

    addPlayer(newPlayerData) {
        const isLocalPlayer = newPlayerData.id == GLOBALS.LOCAL_PLAYER_ID;
        const newPlayer = new Player(newPlayerData, isLocalPlayer);
        this.players.push(newPlayer);
        if (isLocalPlayer)
            this.localPlayer = newPlayer;

        return newPlayer;
    }

    removePlayer(playerId) {
        if (playerId == GLOBALS.LOCAL_PLAYER_ID)
            window.location.reload();

        const removedPlayerIndex = this.players.findIndex(player => player.id == playerId);
        if (removedPlayerIndex > -1) {
            this.players[removedPlayerIndex].dispose();
            this.players.splice(removedPlayerIndex, 1);
        }
        if (this.players.length == 0)
            window.location.reload();
    }

    getLocalPlayer() {
        return this.localPlayer;
    }

    getPlayers() {
        return this.players;
    }

    getPlayersAwakeCount() {
        return this.players.reduce((result, player) => player.ball.isSleeping ? result : result+1, 0);
    }

    updatePlayerTexts() {
        const sortedPlayers = this.players.sort(Player.compare);
        sortedPlayers.forEach((player, index) => {
            player.updatePlayerText(index);
            player.resetPlayerTextFinished();
        });

        const finishedPlayers = this.players
            .filter(player => player.hasFinished())
            .sort(Player.compareFinished);
        finishedPlayers.forEach((player, index) => {
            player.updatePlayerTextFinished(index);
        });
    }

    updateInfoTexts() {
        this.fpsText.setText(Math.round(GLOBALS.GAME.loop.actualFps) + " fps " + (this.fpsTooLowForPhysics ? "!" : ""));

        const roundString = "Round " + this.roundNumber + " / " + this.maxRoundNumber;
        this.roundText.setText(roundString);

        if (!this.level) {
            this.timeLeftText.setText("---");
        } else if (this.status == "END_OF_ROUND" || this.status == "WAITING_FOR_SCORES") {
            this.timeLeftText.setText("END OF ROUND");
        } else if (this.status == "END_OF_GAME") {
            this.timeLeftText.setText("END OF GAME");
        } else if (this.status == "START_OF_ROUND") {
            const timeToStart = MultigolfScene.PARAMS.START_OF_ROUND_TIME * 1000 - this.level.getTimeElapsed();
            this.timeLeftText.setText("Starting in " + Math.floor(timeToStart/1000) + " s");
        } else {
            const timeElapsed = this.level.getTimeElapsed();
            const timeLeft = Math.floor(MultigolfScene.PARAMS.ROUNDTIME - timeElapsed / 1000);
            this.timeLeftText.setText(timeLeft + " s");
        }
    }
}